
<template>
  <div>
    
     

    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>        
         
          <!-- <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col> -->

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">

          <b-row>
            
             <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Parceiro:"  >

                  <multiselect
                    v-model="CodParc"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodParcOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>


              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Tabela:" >
                <multiselect
                    v-model="CodTab"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodTabOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  >
                  </multiselect>

              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            
             <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Produto:" >

                  <multiselect
                    v-model="CodProd"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodProdOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>


              <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Grupo:" >

                  <multiselect
                    v-model="CodGrupoProd"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodGrupoProdOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col md="6" xl="2" class="mb-1">
              <b-form-group label="FDA:" >
                <multiselect
                    v-model="FDA"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="FDAOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

            <b-col md="6" xl="2" class="mb-1">
              <b-form-group label="Linguagem:" >
                <multiselect
                    v-model="Linguagem"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="LinguagemOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

            <b-col md="6" xl="2" class="mb-1">
              <b-form-group label="Exibir Fotos:" >
                <multiselect
                    v-model="ExibeFoto"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="ExibeFotoOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
           
            </b-col>


            <b-col md="6" xl="6" class="mb-1" v-if="ExibeFoto != null && ExibeFoto.code == 'S'" >              
              
              <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Atenção !</h4>
                      <div class="alert-body">
                        A Tabela de Preço com as Fotos dos Produto é <b>MAIS</b> pesada e demora <b>MAIS</b> para ser consultada.
                        <br><br>
                        <b>Certifique-se</b> que realmente precisa das imagens na Tabela antes de consultar.
                      </div>
              </div>
            </b-col>


          </b-row>

              


        <b-row>
            
            <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>
              <div class="DivBotaoLadoLado" >
            
            <b-button 
              variant="primary"
              type="submit"
              @click="ConsultarRelatorio"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
          </b-col>
         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>


  </div>
</template>
<script>

import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
  BImg 
  
  
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
    BImg,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: '',
      CodParc: [],
      CodParcOption: [],
      CodTab: [],
      CodTabOption: [],
      FDA: { "name": "Todos", "code": "T" },
      FDAOption: [
        { name: 'Todos', code: 'T' },
        { name: 'Sim', code: 'S' },
        { name: 'Não', code: 'N' },
      ],     
      Linguagem: { "name": "Português", "code": "pt-br" },
      LinguagemOption: [
        { name: 'Português', code: 'pt-br' },
        { name: 'Ingles', code: 'en-us' },
        { name: 'Espanhol', code: 'sb-sb' },
      ],     
      ExibeFoto: { "name": "Não", "code": "N" },
      ExibeFotoOption: [
        { name: 'Não', code: 'N' },
        { name: 'Sim', code: 'S' },
      ], 
      CodProd: [],
      CodProdOption: [],
      CodGrupoProd: [],
      CodGrupoProdOption: [],
      Linha: '',
      ProdutoLinha: [],
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Cliente`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.CodParcOption = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=TabPreco`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CodTabOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=TabPrecoProduto`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CodProdOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


    if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=TabPrecoGrupo`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CodGrupoProdOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio() {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      //Fim Dados Base Relatorio
      
      //Inicio Limpa Dados Retorno
      // Fim Limpa Dados Retorno

      //Inicio Pega parametros de Parametros
      if(this.CodParc != null){
        if(Array.isArray(this.CodParc) == false)
        {
          const Filtro = {
            parametro: 'CODPARC',
            valor: this.CodParc.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

      if(this.CodTab != null){
        if(Array.isArray(this.CodTab) == false)
        {
          const Filtro = {
            parametro: 'CODTAB',
            valor: this.CodTab.code.toString(),
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

     if(this.FDA != null){
        if(Array.isArray(this.FDA) == false)
        {
          const Filtro = {
            parametro: 'FDA',
            valor: this.FDA.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }


      if(this.Linguagem != null){
        if(Array.isArray(this.Linguagem) == false)
        {
          const Filtro = {
            parametro: 'Linguagem',
            valor: this.Linguagem.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }
      
          
      this.CodProd.forEach(Item => {
        const Filtro = {
          parametro: 'CODPROD',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })
    
      this.CodGrupoProd.forEach(Item => {
        const Filtro = {
          parametro: 'CODGRUPOPROD',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })

      if(this.ExibeFoto != null){
        if(Array.isArray(this.ExibeFoto) == false)
        {
          const Filtro = {
            parametro: 'ExibeFoto',
            valor: this.ExibeFoto.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }
      
      //Fim Pega parametros de Parametros
    

      let ParametroJson = ''
      let URLpost = ''
      let TabResponse = null

      if(notification === '') {
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelTabelaPreco?CODUSU=${usuario.codusu}&Nivel=TAB1&TypeRDLCexport=pdf`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
             TabResponse = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

      if(TabResponse != null)
      {
        await UtilVUE.DownloadFile(TabResponse.reportByte, TabResponse.descricaoRelatorio + TabResponse.extensao)            
      }

    }
     if(notification === '') {
       Spinner.EndLoad()
     }

    },
  },
  computed: {
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.PeridoIni = new Date()
      this.PeridoFin = new Date()
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>



